import {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useTransitionState, useTriggerTransition} from 'gatsby-plugin-transition-link/hooks';
import Link from 'gatsby-link';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {Layout, Header} from '../components/layout';
import {entryAnimation} from '../utils/animations/case';
import caseStudies from '../data/caseStudies';
import pageTypes from '../utils/enums/pageType';
import * as styles from './caseDetail.module.css';

const CasePage = ({
    pageContext: {id},
    location,
}) => {
    const {
        headerColor,
        imageSrc,
        detail = {},
    } = caseStudies.find(item => item.slug === id);

    const sendDataToGTM = useGTMDispatch();
    const caseHeroImageRef = useRef();
    const caseHeroTitleRef = useRef();
    const {entry} = useTransitionState();

    const entryTransition = useTriggerTransition({
        to: location.pathname,
        entry: {
            length: 0.5,
            state: {
                pageType: pageTypes.case,
                nextPageType: pageTypes.case,
            },
        },
    });

    useEffect(() => {
        if (entry && Object.keys(entry.state).length > 0 && entry.state.isTransition) {
            return;
        }

        entryAnimation({
            pageRef: document.body,
            caseHeroImage: caseHeroImageRef.current,
            caseHeroTitle: caseHeroTitleRef.current,
            isTransition: false,
        });
        entryTransition({});
    // eslint-disable-next-line
    }, []);

    return (
        <Layout>
            <Header pageType={pageTypes.case} headerColor={headerColor}>
                <div className={styles.header}>
                    <div className={styles.headerVisual}>
                        <Link
                            to="/cases"
                            onClick={() => {
                                sendDataToGTM({
                                    event: 'linkClick',
                                    linkText: 'Projekty',
                                    targetURL: '/cases',
                                });
                            }}
                            className={styles.backLink}
                        >
                            <span>
                                <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M23 12L13 2M2.16667 12L23 12L2.16667 12ZM23 12L13 22L23 12Z"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            Projekty
                        </Link>
                        <img
                            src={imageSrc}
                            alt=""
                            data-animation-image="case"
                            ref={caseHeroImageRef}
                            className={styles.headerImage}
                        />
                        <span className={styles.border}/>
                    </div>
                    <h1
                        className={styles.headerTitle}
                        data-animation-type="case-title"
                        ref={caseHeroTitleRef}
                    >
                        <span className={styles.headerTitleTop}>
                            <span className={styles.text}>{detail.heroText1}</span>
                        </span>
                        <span className={styles.headerTitleBottom}>
                            <span className={styles.text}>{detail.heroText2}</span>
                        </span>
                    </h1>
                </div>
            </Header>
            <div className={styles.caseStudy} data-animation-copy="fade-in-entry">
                {detail.contentComponent && detail.contentComponent()}
            </div>
        </Layout>
    );
};

CasePage.propTypes = {
    pageContext: PropTypes.any,
    location: PropTypes.any,
    case: PropTypes.any,
};

export default CasePage;
