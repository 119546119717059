// extracted by mini-css-extract-plugin
export var backLink = "caseDetail-module--backLink--RxtOS";
export var border = "caseDetail-module--border--jPQk5";
export var caseStudy = "caseDetail-module--caseStudy--qUs-I";
export var frame = "caseDetail-module--frame--rYM3J";
export var header = "caseDetail-module--header--VuHZv";
export var headerImage = "caseDetail-module--headerImage--O1129";
export var headerTitle = "caseDetail-module--headerTitle--dWNLq";
export var headerTitleBottom = "caseDetail-module--headerTitleBottom--5N3n5";
export var headerTitleTop = "caseDetail-module--headerTitleTop--xiJAj";
export var headerVisual = "caseDetail-module--headerVisual--4JEUE";
export var text = "caseDetail-module--text--oUjmv";
export var titleEnter = "caseDetail-module--title-enter--lo-DO";